import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AppContext from "../../store/AppContext";
import ConsultaWhoisService from "../../services/ConsultaWhoisService";
import {ConsultaWhoisPanelResponse} from "./ConsultaWhoisPanelResponse";
import './ConsultaWhois.css';
import {TipoMensaje} from "../../store/AppProvider";
import DisponibilidadService from "../../services/DisponibilidadService";
import ReCAPTCHA from "react-google-recaptcha";

const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const ConsultaWhois = () => {
    const appCtx = useContext(AppContext);
    const recaptchaRef = useRef();
    const [resultadoConsultaWhois, setResultadoConsultaWhois] = useState(undefined);
    const [dominio, setDominio] = useState('');
    const [dominiosHabilitados, setDominiosHabilitados] = useState('');
    const [captchaResponse, setCaptchaResponse] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(true);
    let location = useLocation();
    let history = useNavigate();

    useEffect(() =>{
        mostrarCaptcha();
    }, [appCtx])

    useEffect(() => {
        mostrarCaptcha();
        obtenerDominiosHabilitados();
        if (location.state != null && location.state.dominioDisponibilidad !== '') {
            // eslint-disable-next-line
            let dominioDisponibilidad = location.state.dominioDisponibilidad;
            setDominio(location.state.dominioDisponibilidad);
            consultaWhoisService(dominioDisponibilidad);
        }
        appCtx.ocultarMensaje();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const consultarWhois = (e) => {
        e.preventDefault();

        if (!dominio) {
            return;
        }
        const nombreDominio = eliminarEspacios(dominio);
        setDominio(nombreDominio);

        if (!validarDominio(nombreDominio.toLowerCase())) {
            if (showCaptcha){
                recaptchaRef.current.reset();
            }
            if (nombreDominio.split('.').length - 2 > 0) {
                appCtx.mostrarMensaje('El dominio no debe contener otro punto (.) en medio del nombre y la extensión.', TipoMensaje.error);
                return;
            }
            else if (!nombreDominio.endsWith('.com.uy') && !nombreDominio.endsWith('.uy')) {
                appCtx.mostrarMensaje('El dominio ingresado debe terminar en .com.uy o .uy', TipoMensaje.error);
            } else if (nombreDominio.includes(' ')) {
                appCtx.mostrarMensaje('El dominio no debe contener espacios.', TipoMensaje.error);
            }
            else {
                appCtx.mostrarMensaje('El dominio ingresado tiene formato inválido. Debe terminar en .com.uy o .uy', TipoMensaje.error);
            }
            return;
        }

        consultaWhoisService(nombreDominio);
    }

    const consultaWhoisService = (dominio) => {
        const dominoConsulta = dominio.toLowerCase();
        ConsultaWhoisService.getWhois(dominoConsulta, captchaResponse)
            .then(response => {
                if (response.esRespOK || response.respCodigo === 'ERROR_DOMINIO_NO_EXISTE') {
                    appCtx.ocultarMensaje();
                    setResultadoConsultaWhois(response);
                    setDominio(response.dominio);
                } else if (response.respCodigo === 'CAPTCHA_INVALIDO') {
                    appCtx.mostrarMensaje(response.respMensaje, TipoMensaje.error);
                    setResultadoConsultaWhois(undefined);
                } else if (response.respCodigo === 'PARAMETRO_REQUERIDO') {
                    appCtx.mostrarMensaje(response.respMensaje + " Debe terminar en " + dominiosHabilitados, TipoMensaje.error);
                    setResultadoConsultaWhois(undefined);
                } else if (response.respCodigo ==='ERROR_DOMINIO_NO_PERTENECE'){
                    appCtx.mostrarMensaje(response.respMensaje, TipoMensaje.error);
                    setResultadoConsultaWhois(undefined);
                }
                else {
                    appCtx.mostrarMensaje('Lo sentimos, ha ocurrido un error. Por favor, inténtelo nuevamente.', TipoMensaje.error);
                    setResultadoConsultaWhois(undefined);
                }
                if (showCaptcha) {
                    recaptchaRef.current.reset();
                }
            })
            .catch(() => {
                appCtx.mostrarMensaje('Lo sentimos, ha ocurrido un error. Por favor, inténtelo nuevamente.', TipoMensaje.error);
                setResultadoConsultaWhois(undefined);
            });
    }

    const obtenerDominiosHabilitados = () => {
        DisponibilidadService.getDominiosHabilitados().then((response) => {
            if (response && response.respuestaOK) {
                let domsHabilitados = '';
                for (let index = 0; index < response.dominiosHabilitados.length; index++) {
                    if (response.dominiosHabilitados.length === index + 1) {
                        domsHabilitados = domsHabilitados + response.dominiosHabilitados[index];
                    } else {
                        if (response.dominiosHabilitados.length === index + 2) {
                            domsHabilitados = domsHabilitados + response.dominiosHabilitados[index] + ' o ';
                        } else {
                            domsHabilitados = domsHabilitados + response.dominiosHabilitados[index] + ", ";
                        }
                    }
                    setDominiosHabilitados(domsHabilitados);
                }
            }
        })
    }

    const mostrarCaptcha = () => {
        DisponibilidadService.mostrarCaptcha().then(response => {
            if (response !== undefined) {
                if (response.codigo === 'OK') {
                    setShowCaptcha(response.resultado);
                }
            }
        })
    }

    const handleOnChange = (e) => {
        const { value } = e.target;
        setDominio(value);
    }

    function onChange(value) {
        setCaptchaResponse(value);
    }

    function eliminarEspacios(cadena) {
        return cadena.replace(/\s/g, '');
    }

    function validarDominio(dominio) {
        // Expresión regular para validar dominios que terminan en ".com.uy" o ".uy"
        const regexDominio = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?)+(?:\.com\.uy|\.uy)$/;
        return regexDominio.test(dominio);
    }

    return <>
        <h2>Consultar Whois</h2>
        <div className="whois-search">
            <form id="id-form-whois-query" name="id-form-whois-query" onSubmit={consultarWhois}>
                <div>
                    <p>Este servicio le permite conocer la información pública de los dominios y sus contactos.</p>
                    <input type="text" name="text" maxLength={50} value={dominio} onChange={handleOnChange} />
                    <input type="submit" value="Buscar"/>
                    { showCaptcha &&
                        <div className={"box-captcha cfx"}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={REACT_APP_SITE_KEY}
                            onChange={onChange}
                        />
                    </div>}
                </div>
            </form>
        </div>

        { resultadoConsultaWhois?
            <ConsultaWhoisPanelResponse resultadoConsultaWhois={resultadoConsultaWhois}/> : <></>
        }
    </>
}
import React, {useEffect, useState} from 'react';
import Modal from "../../../Modal";
import AdministracionUsuarioService from "../../../../services/AdministracionUsuarioService";
import {MensajeError} from "../../../../view/MensajeError";
import {useValidacionesRegistro} from "../../../validaciones/ValidacionesRegistro";

export const ModalAgregarRegistroDominio = ({ dominio, nombreDominio, tipoRegistroCrear , estructurasRegistros, tiposRegistro,
                                                actualizarInformacionRegistros, setMensajeOk,
                                                handleOnClose, isOpen, handleOnCloseOk, registros }) => {

    const[campos, setCampos] = useState([]);
    const[estructuraRegistroActual, setEstructuraRegistroActual] = useState({});
    const[tipoRegistroActual, setTipoRegistroActual] = useState(tipoRegistroCrear);

    const [mostrarInfoSubdominio, setMostrarInfoSubdominio] = useState(false);
    const [infoSubdominio, setInfoSubdominio] = useState(<></>);
    const [storedRegistros, setStoredRegistros] = useState([]);
    const [ttl, setTtl] = useState('24hrs');

    const [camposError, setCamposError] = useState(undefined);

    const [error, setError] = useState({
        error: false,
        mensaje: "Sin errores"
    });

    const handleTtlChange = (e) => {
        setTtl(e.target.value);
    };
    const validacionesRegistro = useValidacionesRegistro();

    function cargarEstructuraCampos(tipoReg) {
        let estructurasRegistro = estructurasRegistros[tipoReg];
        if (estructurasRegistro !== undefined){
            setEstructuraRegistroActual(estructurasRegistro);
            if (estructurasRegistro.estructuraCampos !== undefined){
                let camposEstructura = estructurasRegistro.estructuraCampos.map( ec => {return {
                    etiqueta : ec.etiqueta,
                    nombre : ec.nombre,
                    descripcion : ec.descripcion,
                    valor : ''
                }});
                setCampos(camposEstructura);
            }
        }
        cargarInfoSubdominio(tipoReg)
    }
    useEffect(() => {
        if (registros) {
            setStoredRegistros(registros);
        }
    }, [registros]);

    useEffect(() => {
    }, [storedRegistros]);
    useEffect(() => {
        cargarEstructuraCampos(tipoRegistroActual);
    }, []);

    function isMostrarSubdominioTipoRegistro(tipoReg) {
        if (tipoReg === 'CNAME' || tipoReg === 'MX' || tipoReg === 'NS' || tipoReg === 'SRV'){
            return true;
        }
        return false;
    }

    function getValorCampo(nombreCampo) {
        let campo = campos.find((campo) => campo.nombre === nombreCampo);
        if (campo !== undefined){
            return campo.valor;
        }
        return '';
    }

    function actualizarInfoSubdominio(tipoReg) {
        switch (tipoReg){
            case 'CNAME':
                let cname = getValorCampo('cname');
                let cannonical_name = getValorCampo('cannonical_name');
                let infoNombre = cname.slice(-1) !== "." ? "Alias resultante: " + cname + "." + nombreDominio + "." : "Alias resultante: " + cname;
                let infoServidor = cannonical_name.slice(-1) !== "." ? "Nombre resultante: " + cannonical_name + "." + nombreDominio + "." : "Nombre resultante: " + cannonical_name;
                setInfoSubdominio(<>
                    <div><text>{infoNombre}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidor}</text></div>
                </>)
                break
            case 'MX':
                let name = getValorCampo('name');
                let mail_server = getValorCampo('mail_server');
                let infoNombreMX = name.slice(-1) !== "." ? "Nombre resultante: " + name + "." + nombreDominio + "." : "Nombre resultante: " + name;
                let infoServidorMX = mail_server.slice(-1) !== "." ? "Servidor resultante: " + mail_server + "." + nombreDominio + "." : "Servidor resultante: " + mail_server;
                setInfoSubdominio(<>
                    <div><text>{infoNombreMX}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidorMX}</text></div>
                </>)
                break
            case 'NS':
                let nameNS = getValorCampo('name');
                let target = getValorCampo('target');
                let infoNombreNS = nameNS.slice(-1) !== "." ? "Nombre resultante: " + nameNS + "." + nombreDominio + "." : "Nombre resultante: " + nameNS;
                let infoServidorNS = target.slice(-1) !== "." ? "Servidor resultante: " + target + "." + nombreDominio + "." : "Servidor resultante: " + target;
                setInfoSubdominio(<>
                    <div><text>{infoNombreNS}</text></div>
                    {/*<br/>*/}
                    <div><text>{infoServidorNS}</text></div>
                </>)
                break;
            case 'SRV':
                let srv_name = getValorCampo('srv_name');
                let targetSRV = getValorCampo('target');
                let infoNombreSRV = srv_name.slice(-1) !== "." ? "Nombre resultante: " + srv_name + "." + nombreDominio + "." : "Nombre resultante: " + srv_name;
                let infoServidorSRV = targetSRV.slice(-1) !== "." ? "Servidor resultante: " + targetSRV + "." : "Servidor resultante: " + targetSRV;
                setInfoSubdominio(<>
                    <div><text>{infoNombreSRV}</text></div>
                    <div><text>{infoServidorSRV}</text></div>
                </>)
                break;
            default:
                setInfoSubdominio(<></>)
        }
    }


    function cargarInfoSubdominio(tipoReg) {
        if (isMostrarSubdominioTipoRegistro(tipoReg)){
            setMostrarInfoSubdominio(true);
            actualizarInfoSubdominio(tipoReg);
        }else{
            setMostrarInfoSubdominio(false);
            actualizarInfoSubdominio(tipoReg);
        }
    }

    const onChangeComboTipoRegistro = (event) => {
        const option = event.target.value;
        setTipoRegistroActual(option);
        cargarEstructuraCampos(option);
        setMensajeOk({mostrarMensaje : false, mensaje: ""})
        setError({error : false, mensaje: "Sin errores"});
    }

    const handleOnChangeValorCampo = (e) => {
        let camposNuevo = campos.map((campo) => {
            if (campo.nombre === e.target.name){
                campo.valor = e.target.value;
            }
            return campo;
        })
        setCampos(camposNuevo);

        if (isMostrarSubdominioTipoRegistro(tipoRegistroActual)) {
            actualizarInfoSubdominio(tipoRegistroActual);
        }

    }

    function procesarCamposError(camposError) {
        let camposConError = [];
        camposError.forEach(c => camposConError.push(c.nombre));
        setCamposError(camposConError);
    }

    const agregarRegistro = (e) => {
        e.preventDefault();

        setMensajeOk({ mostrarMensaje: false, mensaje: "" });
        setError({ error: false, mensaje: "Sin errores" });
        setCamposError([]);

        if (validacionesRegistro.validarCamposObligatorios(tipoRegistroActual, campos, setCamposError)) {
            setError({
                error: true,
                mensaje: "Debe completar todos los campos obligatorios."
            });
            return;
        }

        if (tipoRegistroActual === 'SRV' && validacionesRegistro.validarCamposSRV(tipoRegistroActual, campos, setCamposError)) {
            setError({
                error: true,
                mensaje: "El nombre del servidor del registro SRV debe ser FQDN y deben finalizar con un punto."
            });
            return;
        }
        let valorNombreCampo = validacionesRegistro.getValorNombreRegistro(campos);

        if (!validacionesRegistro.validarNombreDuplicado(tipoRegistroActual, valorNombreCampo, storedRegistros, setError, false, undefined)){
            return;
        }

        if (!validacionesRegistro.validarValorIpRegistro(tipoRegistroActual, campos, setError, setCamposError)){
            return;
        }

        let crearRegistro = {
            nombreDominio: nombreDominio,
            agregarRegistro: true,
            registro: {
                tipo: tipoRegistroActual,
                ttl: convertirTtlASegundos(ttl),
                estructura: estructuraRegistroActual,
                campos: campos
            }
        };


        AdministracionUsuarioService.modificarRegistroDominio(crearRegistro)
            .then(response => {
                if (!response.respuestaOK) {
                    setError({
                        error: true,
                        mensaje: response.mensajeRespuesta
                    });
                    if (response.camposConError && response.camposError) {
                        procesarCamposError(response.camposError);
                    }
                } else {
                    actualizarInformacionRegistros(dominio);
                    setMensajeOk({ mostrarMensaje: true, mensaje: "El registro se agregó correctamente" });
                    handleOnCloseOk({ mostrarMensaje: true, mensaje: "El registro se agregó correctamente" });
                }
            })
            .catch(() => {
                setError({
                    error: true,
                    mensaje: "Ha ocurrido un error al agregar registro al dominio."
                });
            });
    };

    const convertirTtlASegundos = (ttl) => {
        switch (ttl) {
            case '10min':
                return 10 * 60;
            case '30min':
                return 30 * 60;
            case '60min':
                return 60 * 60;
            case '6hrs':
                return 6 * 60 * 60;
            case '12hrs':
                return 12 * 60 * 60;
            case '24hrs':
            default:
                return 24 * 60 * 60;
        }
    };


    const  styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? 'red' : '';
        }
    }

    return <>
        <Modal
            idDivModal="idModalRegistroDominio"
            handleClose={handleOnClose} isOpen={isOpen} closeOnClickOutside={false}>
            <fieldset id="id-fieldset-editar-registro">
                <legend>Crear registro</legend>
                <p id="descripcion-registro"><strong>
                    {estructuraRegistroActual.descripcion}
                </strong></p>

                {error !== undefined && error.error && <MensajeError mensajeError={error.mensaje}/>}

                <form id="id-form-agregar-registro">
                    <div className="form-item">
                        <label htmlFor="idSelectTipoRegistro">Tipo de registro</label>
                        <select id="idSelectTipoRegistro" value={tipoRegistroActual} name={"tipoRegistro"}
                                onChange={onChangeComboTipoRegistro}>
                            {tiposRegistro && tiposRegistro.map((tipo) => (
                                <option key={tipo} value={tipo}>{tipo}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-item">
                        <label htmlFor="idSelectTtl">TTL</label>
                        <select id="idSelectTtl" value={ttl} name={"ttl"} onChange={handleTtlChange}>
                            <option value="10min">10min</option>
                            <option value="30min">30min</option>
                            <option value="60min">60min</option>
                            <option value="6hrs">6hrs</option>
                            <option value="12hrs">12hrs</option>
                            <option value="24hrs">24hrs</option>
                        </select>
                    </div>
                    <div id="id-estructura">
                        {campos.map((estructuraCampo, index) => (
                            <div className="form-item" id={'id-div-' + index} key={index}>
                                <label>{estructuraCampo.etiqueta}</label>
                                {estructuraCampo.nombre === 'service' ? (
                                    <select
                                        id={"id-service-" + index}
                                        name={estructuraCampo.nombre}
                                        value={estructuraCampo.valor}
                                        onChange={handleOnChangeValorCampo}
                                        className="edit"
                                        style={{borderColor: styleError(estructuraCampo.nombre)}}
                                    >
                                        <option value="">Selecione el servicio</option>
                                        <option value="_sip">SIP</option>
                                        <option value="_http">HTTP</option>
                                        <option value="_ftp">FTP</option>
                                        <option value="_ldap">LDAP</option>
                                    </select>
                                ) : estructuraCampo.nombre === 'protocol' ? (
                                    <select
                                        id={"id-protocol-" + index}
                                        name={estructuraCampo.nombre}
                                        value={estructuraCampo.valor}
                                        onChange={handleOnChangeValorCampo}
                                        className="edit"
                                        style={{borderColor: styleError(estructuraCampo.nombre)}}
                                    >
                                        <option value="">Seleccione el protocolo</option>
                                        <option value="_tcp">TCP</option>
                                        <option value="_udp">UDP</option>
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        id={"id-" + {index}}
                                        name={estructuraCampo.nombre}
                                        value={estructuraCampo.valor}
                                        onChange={handleOnChangeValorCampo}
                                        className="edit"
                                        style={{borderColor: styleError(estructuraCampo.nombre)}}
                                    />
                                )}

                                {estructuraCampo.descripcion !== undefined && estructuraCampo.descripcion !== null && (
                                    <p className="note">
                                        {estructuraCampo.descripcion}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>

                </form>
                <div id="id-message" style={{
                    margin: "20px 0",
                    padding: "10px",
                    backgroundColor: "#d1ecf1", color: "#0c5460",
                    border: "1px solid #bee5eb", borderRadius: "4px",
                    width: "600px"
                }}>
                    <strong>Información importante:</strong> Para los registros CNAME, MX y NS, SRV, añade un punto al final
                    para
                    indicar
                    un nombre de dominio completo (FQDN). Sin este punto, se añadirá el nombre de tu dominio al valor de
                    tu registro.
                    Por favor, verifica el resultado de la configuración antes de finalizar la edición del registro.
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && <strong>Resultado:</strong>}
                    {mostrarInfoSubdominio && <br/>}
                    {mostrarInfoSubdominio && infoSubdominio}
                </div>
                <div className="right">
                    <button id="id-submit-guardar-datos-contacto" className="btn" onClick={agregarRegistro}>Crear
                        registro
                    </button>
                </div>
            </fieldset>
        </Modal>
    </>
}
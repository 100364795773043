
export const useValidacionesRegistro = () => {


    function validarCamposObligatorios (tipoReg, campos, setCamposError) {
        let camposConError = [];
        switch (tipoReg){
            case 'CNAME':
                checkErrorVacio('cname', getValorCampo('cname', campos), camposConError);
                checkErrorVacio('cannonical_name', getValorCampo('cannonical_name', campos), camposConError);
                break
            case 'MX':
                checkErrorVacio('name', getValorCampo('name', campos), camposConError);
                checkErrorVacio('pri', getValorCampo('pri', campos), camposConError);
                checkErrorVacio('mail_server', getValorCampo('mail_server', campos), camposConError);
                break
            case 'NS':
                checkErrorVacio('name', getValorCampo('name', campos), camposConError);
                checkErrorVacio('target', getValorCampo('target', campos), camposConError);
                break
            case 'A':
                checkErrorVacio('name', getValorCampo('name', campos), camposConError);
                checkErrorVacio('ip', getValorCampo('ip', campos), camposConError);
                break
            case 'AAAA':
                checkErrorVacio('name', getValorCampo('name', campos), camposConError);
                checkErrorVacio('ipv6', getValorCampo('ipv6', campos), camposConError);
                break
            case 'TXT':
                checkErrorVacio('txt_name', getValorCampo('txt_name', campos), camposConError);
                checkErrorVacio('text', getValorCampo('text', campos), camposConError);
                break
            case 'SRV':
                checkErrorVacio('srv_name', getValorCampo('srv_name', campos), camposConError);
                checkErrorVacio('target', getValorCampo('target', campos), camposConError);
                checkErrorVacio('pri', getValorCampo('pri', campos), camposConError);
                checkErrorVacio('weight', getValorCampo('weight', campos), camposConError);
                checkErrorVacio('port', getValorCampo('port', campos), camposConError);
                break
            default:
        }

        setCamposError(camposConError);
        return camposConError.length > 0;
    }

    function validarCamposSRV(tipoReg, campos, setCamposError) {
        let camposConError = [];

        switch (tipoReg) {
            case 'SRV':
                // let srvName = getValorCampo('srv_name', campos);
                // checkErrorVacio('srv_name', srvName, camposConError);
                //
                // if (srvName && !srvName.endsWith('.')) {
                //     camposConError.push('srv_name');
                // }

                let srvTarget = getValorCampo('target', campos);
                checkErrorVacio('target', srvTarget, camposConError);

                if (srvTarget && !srvTarget.endsWith('.')) {
                    camposConError.push('target');
                }
                break;
        }

        setCamposError(camposConError);
        return camposConError.length > 0;
    }

    function getValorNombreRegistro (campos) {
        const nombreCampo = campos.find(c => c.nombre === 'txt_name' || c.nombre === 'name' || c.nombre === 'hostname' || c.nombre === 'cname');
        let valorNombreCampo = nombreCampo ? nombreCampo.valor : '';
        valorNombreCampo = valorNombreCampo.replace(/\.$/, '');  // Eliminar el punto final si existe
        return valorNombreCampo;
    }

    function validarNombreDuplicado (tipoRegistroActual, valorNombreCampo, storedRegistros, setError, modoEditar, idInternoRegistro) {

        const tiposRegistroI = ['A', 'TXT'];
        const tiposRegistroII = ['CNAME'];
        const tiposRegistroU = ['CNAME', 'A', 'TXT'];

        let registroExistente;

        if (tiposRegistroI.includes(tipoRegistroActual)) {
            registroExistente = storedRegistros.find(registro =>
                tiposRegistroII.includes(registro.tipo) &&
                (!modoEditar || (modoEditar && registro.idInterno !== idInternoRegistro)) &&
                registro.campos.some(campo =>
                    campo.nombre === 'cname' &&
                    campo.valor.replace(/\.$/, '') === valorNombreCampo
                )
            );
        } else if (tiposRegistroII.includes(tipoRegistroActual)) {
            registroExistente = storedRegistros.find(registro =>
                tiposRegistroU.includes(registro.tipo) &&
                (!modoEditar || (modoEditar && registro.idInterno !== idInternoRegistro)) &&
                registro.campos.some(campo =>
                    (campo.nombre === 'txt_name' || campo.nombre === 'name' || campo.nombre === 'hostname' || campo.nombre === 'cname') &&
                    campo.valor.replace(/\.$/, '') === valorNombreCampo
                )
            );
        }

        if (registroExistente){
            let mensaje
            if (registroExistente.tipo === 'CNAME'){
                mensaje = `Ya existe un registro ${registroExistente.tipo} con Alias ${valorNombreCampo}.`
            } else {
                mensaje = `Ya existe un registro ${registroExistente.tipo} con Nombre ${valorNombreCampo}.`
            }
            setError({
                error: true,
                mensaje: mensaje
            });
            return false;
        }
        return true;
    }

    function validarValorIpRegistro(tipoRegistro, campos, setError, setCamposError){
        let camposConError = [];
        const tiposRegistro = ['AAAA', 'A'];
        if (!tiposRegistro.includes(tipoRegistro)){
            return true;
        }

        const campo = campos.find(c => c.nombre === 'ipv6' || c.nombre === 'ip');
        if (campo.nombre === 'ipv6'){
            if (!validarIPv6(campo.valor)){
                setError({
                    error: true,
                    mensaje: "Existen errores en los campos. Verifiquelos e intente nuevamente."
                });
                camposConError.push(campo.nombre);
                setCamposError(camposConError);
                return false;
            }
            return true;
        }
        if (campo.nombre === 'ip'){
            if (!validarIp(campo.valor)){
                setError({
                    error: true,
                    mensaje: "Existen errores en los campos. Verifiquelos e intente nuevamente."
                });
                camposConError.push(campo.nombre);
                setCamposError(camposConError);
                return false;
            }
            return true;
        }
        return true;
    }

    const validarIPv6 = (address) => {
        const ipv6Pattern = new RegExp(
            '^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,7}:$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,5}(?::[a-fA-F0-9]{1,4}){1,2}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,4}(?::[a-fA-F0-9]{1,4}){1,3}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,3}(?::[a-fA-F0-9]{1,4}){1,4}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,2}(?::[a-fA-F0-9]{1,4}){1,5}$|' +
            '^[a-fA-F0-9]{1,4}:(?::[a-fA-F0-9]{1,4}){1,6}$|' +
            '^:(?::[a-fA-F0-9]{1,4}){1,7}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){1,7}(?::[a-fA-F0-9]{1,4}){1,7}$|' +
            '^(?:[a-fA-F0-9]{1,4}:){7,7}(?::[a-fA-F0-9]{1,4}){7,7}$'
        );

        return ipv6Pattern.test(address);
    }

    const validarIp = (address) => {
        const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipv4Pattern.test(address);
    };

    const checkErrorVacio = (campoAValidar, valor, listaErrores) => {
        if (!Array.isArray(listaErrores)) {
            listaErrores = [];
        }

        if (chequearVacio(valor.trim())) {
            listaErrores.push(campoAValidar);
        } else {
            listaErrores = listaErrores.filter((campo) => campo !== campoAValidar);
        }

        return listaErrores;
    }

    const chequearVacio = (texto) => {
        return texto.length === 0;
    }

    function getValorCampo(nombreCampo, campos) {
        let campo = campos.find((campo) => campo.nombre === nombreCampo);
        if (campo !== undefined){
            return campo.valor;
        }
        return '';
    }

    return { validarCamposObligatorios, getValorNombreRegistro, validarNombreDuplicado, validarValorIpRegistro,validarCamposSRV};

}
